import React, { Fragment } from 'react'
import { Link } from 'gatsby'
import { nav, navTwitter, navMenu, navMenuLink} from './navigation.module.scss'
import { TWITTER } from '../../config'

/******************************
 * Component
 ******************************/

export default function Navigation (props) {  
  return (
    <nav className={[nav, props.className].join(' ')}>
      <a className={navTwitter} href={TWITTER} target='_blank'>@rojcyk</a>

      <div className={navMenu}>
        <Link to="/" className={navMenuLink}>Blog</Link>
        <Link to="/resources" className={navMenuLink}>Resources</Link>
        <Link to="/about"  className={navMenuLink}>About</Link>
      </div>
    </nav>
  )
}