// import "./src/styles/reset.scss"
import "./src/styles/index.scss"

export const onClientEntry = () => {
  console.log("We've started!")
}

export const onInitialClientRender = () => {
  console.log('ReactDOM.render has executed')
}

export const onRouteUpdate = ({ location, prevLocation }) => {
  console.log('Updating route')
  // setTimeout(() => {}, 0)
  // console.log('Setting new pathname:', location.pathname)
  // console.log('Showing old pathname:', prevLocation ? prevLocation.pathname : null)

  // if (location.hash) {
  //   setTimeout(() => {
  //     document.querySelector(`${location.hash}`).scrollIntoView()
  //   }, 0)
  // }
}

export const onRouteUpdateDelayed = () => {
  console.log('We can show loading indicator now')
}

// const transitionDelay = 900

// export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
//   if (location.action === 'PUSH') {
//     window.setTimeout(() => window.scrollTo(0, 0), transitionDelay)
//   } else {
//     const savedPosition = getSavedScrollPosition(location)
//     window.setTimeout(() => window.scrollTo(...(savedPosition || [0, 0])), transitionDelay)
//   }
//   return false
// }
