// Website config
exports.HOST = 'https://rojcyk.com'
exports.METAIMAGE = '/imgs/meta.png'
// exports.RSSFEED = '/rss.xml'
exports.COLOR = '#000000'

// Social
exports.TWITTER_AUTHOR = 'rojcyk'
exports.TWITTER = `https://twitter.com/rojcyk`
exports.FIGMA = 'https://figma.com/@rojcyk'
exports.GITHUB = 'https://github.com/rojcyk'
exports.LINKEDIN = 'https://www.linkedin.com/in/rojcyk/'
exports.DRIBBBLE = 'https://dribbble.com/rojcyk'

// Meta tags
exports.SITE_AUTHOR = 'Denis Rojcyk'
exports.SITE_DESCRIPTION = 'I help companies design their digital products so that they can grow their business.'