import React from 'react'
import { Helmet } from 'react-helmet'
import { TWITTER_AUTHOR, HOST, METAIMAGE, SITE_AUTHOR, SITE_DESCRIPTION } from '../../config'


interface SEO {
  title?: string,
  description?: string,
  image?: string,
  pathname?: string,
  article?: string
}

const SEO = ({ title, description, image, pathname, article }: SEO) => {
  const seo = {
    title: title || `${SITE_AUTHOR}`,
    description: description || SITE_DESCRIPTION,
    image: `${HOST}${image || METAIMAGE}`,
    url: `${HOST}${pathname || '/'}`,
  }

  return (
    <>
      <Helmet title={seo.title}>
        {/* <!--  Non-Essential, But Recommended -->

        <meta property="og:site_name" content="European Travel, Inc.">
        <meta name="twitter:image:alt" content="Alt text for image">

        <!--  Non-Essential, But Required for Analytics -->

        <meta property="fb:app_id" content="your_app_id" />
        <meta name="twitter:site" content="@website-username"> */}

        <meta name="author" content={SITE_AUTHOR} />

        <meta name="description" content={seo.description} />

        {/* Recommend 1200×628 */}
        <meta name="image" content={seo.image} />

        <meta property="og:url" content={seo.url} />
        {(article ? true : null) && <meta property="og:type" content="article" />}
        <meta property="og:title" content={seo.title} />
        <meta property="og:description" content={seo.description} />
        <meta property="og:image" content={seo.image} />

        <meta name="twitter:creator" content={TWITTER_AUTHOR} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={seo.title} />
        <meta name="twitter:description" content={seo.description} />
        <meta name="twitter:image" content={seo.image} />
      </Helmet>
    </>
  )
}


export default SEO