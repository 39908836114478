import React from 'react'
import { footer } from './footer.module.scss'
import { P } from './paragraph'
import { TWITTER, FIGMA, GITHUB } from '../../config'

/******************************
 * Component
 ******************************/

export default function Footer (props) {  
  return (
    <footer className={[footer, props.className].join(' ')}>
      <P className='spacer-s'><i>This is the end, my awesome friend</i>... but it doesn't have to be! You can reach out at <a target='_blank' href={TWITTER}>Twitter</a>, inspect what I design at <a target='_blank' href={FIGMA}>Figma</a>, and checkout what I code at <a target='_blank' href={GITHUB}>GitHub</a>.</P>
    </footer>
  )
}