import React, { Fragment } from 'react'
import Footer from '../components/footer'
import Navigation from '../components/navigation'
import SEO from '../components/seo'

const Layout = ({ children, location }) => {
  console.log('locatiion', location.pathname)

  return (
    <>
      <SEO pathname={location.pathname} />
      <Navigation className='content' />
      <main className='content'>{children}</main>
      <Footer className='content' />
    </>
  )
}


export default Layout
