
import React from 'react'
import { paragraph } from './paragraph.module.scss'

/******************************
 * Component
 ******************************/

export const P = (props) => {  
  return (
    <p {...props} className={[paragraph, props.className].join(' ')} />
  )
}